import { useAccountInfoContext } from '@/contexts/account-info.context';
import { UserRepository } from '@/repository/user.repository';
import React, { useCallback, useState } from 'react';
import Gems from '@/assets/logo/gems.png';
import { useNavigate } from 'react-router';
import { showNumber } from '@/utilities/number.utils';
import { useQuery } from '@tanstack/react-query';
import { useOnOff } from '@/hooks/use-on-off';
import { ClaimPopup } from '@/components/layout/components/claim';
import Alert from '@/assets/boost/alert-triangle.png';

const POINT_MULTIPLIER = 1000000;
export const HomeInfo = () => {
  const navigate = useNavigate();
  const { userProfile, claimableReward, latestUpdate, isClaimShowPopup, refetchProfile, isFetchingReward } =
    useAccountInfoContext();
  const { isOn: isClaim, turnOn: turnOnClaim, turnOff: turnOffClaim } = useOnOff(false);
  const isActive = true;
  const [isLoading, setIsLoading] = useState(false);
  const loadingText = 'Starting';
  const handleStartFarming = useCallback(async () => {
    setIsLoading(true);
    const res = await UserRepository.startFarming();
    setTimeout(() => {
      refetchProfile();
      setIsLoading(false);
    }, 300);
  }, []);
  const [addedPoint, setAddedPoint] = useState({
    pts: 0,
    hours: 0,
    minutes: 0,
    rate: 0
  });
  const estimatePoint = useCallback(() => {
    const point = Number(userProfile?.point);
    const coreValue = userProfile?.boost_core_value;
    const storageValue = userProfile?.boost_storage_value;
    const multipleValue = userProfile?.boost_multiple_value;

    const timeNow = new Date();
    const timeStart = new Date(userProfile?.last_updated)?.getTime();
    const timeDiff = timeNow.getTime() - (latestUpdate || new Date().getTime());
    console.log('timeDiff', timeDiff);
    const timeDiffInHours = timeDiff / (1000 * 3600);
    const timeCanCount = Math.min(timeDiffInHours, storageValue);
    const _timeDiff = timeNow.getTime() - timeStart;
    const distanceTime = Math.max((userProfile?.boost_storage_value || 1) * 60 * 60 * 1000 - _timeDiff, 0);
    const hours: number = Math.floor((distanceTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes: number = Math.floor((distanceTime % (1000 * 60 * 60)) / (1000 * 60));
    const rate = Math.min(100, _timeDiff / ((userProfile?.boost_storage_value || 1) * 60 * 60 * 10));
    console.log('timeCanCount', timeCanCount, timeDiffInHours, storageValue);
    console.log('coreValue', coreValue);
    console.log('multipleValue', multipleValue);

    const pointCanClaim = coreValue * timeCanCount * multipleValue * POINT_MULTIPLIER;

    return {
      pts: (Math.floor(pointCanClaim) || 0) / POINT_MULTIPLIER,
      hours,
      minutes,
      rate: 100 - rate
    };
  }, [userProfile, latestUpdate]);

  console.log('estimatePoint', estimatePoint());
  // const countInterval = useMemo(() => {
  //   if (addedPoint === 0) return 500;
  //   return (new Date().getTime() - latestUpdate) / addedPoint;
  // }, [addedPoint, latestUpdate]);
  useQuery({
    queryKey: [`estimateUserPoint`, estimatePoint],
    queryFn: () => {
      setAddedPoint(estimatePoint());
      return -1;
    },
    retry: false,
    refetchInterval: 1000,
    enabled: !!userProfile && !isClaimShowPopup
  });
  return (
    <>
      {isClaim && <ClaimPopup onClose={turnOffClaim} addedPoint={addedPoint?.pts} />}
      <div className='w-full  absolute bottom-[100px] px-4 flex flex-col items-center gap-2'>
        <span className='font-normal text-sm '>In storage</span>
        {!userProfile?.start_farming ? (
          <div
            className={`farming-button ${isActive && 'cursor-pointer'} ${isLoading && 'cursor-progress'}`}
            onClick={() => {
              if (!isLoading) {
                handleStartFarming();
              }
            }}
          >
            {isLoading ? (
              <>
                <span>{loadingText}</span>
                <div className='loading-spine'></div>
              </>
            ) : (
              <span>Start Farming</span>
            )}
          </div>
        ) : (
          <div className={`farming-button !h-[56px] relative overflow-hidden cursor-pointer  `} onClick={turnOnClaim}>
            {(addedPoint?.pts === 0 && !isClaimShowPopup) || isFetchingReward ? (
              <div className='loading-spine'></div>
            ) : (
              <>
                {' '}
                <div
                  className='farming-progress absolute h-full bg-[#948E9C] z-[1] right-0'
                  style={{
                    width: `${addedPoint?.rate}%`
                  }}
                ></div>
                <div
                  className={`w-full h-full flex items-center justify-between relative z-[2] px-4 gap-2 ${isClaimShowPopup && '!w-fit'}`}
                >
                  <span className='text-base font-medium text-[#402957]'>
                    {isClaimShowPopup ? 'Claim now' : 'Farming'}
                  </span>
                  <img src={Gems} className='w-8' alt='' />
                  <div className='relative'>
                    <span className='relative opacity-0'>
                      {isClaimShowPopup
                        ? showNumber(claimableReward?.point + addedPoint?.pts)
                        : (claimableReward?.point + addedPoint?.pts)?.toFixed(7)}
                    </span>
                    <span className={`absolute top-0 ${isClaimShowPopup ? 'right-0' : 'left-0'}`}>
                      {showNumber(claimableReward?.point + addedPoint?.pts)}
                    </span>
                  </div>
                  {!isClaimShowPopup && (
                    <span>
                      {addedPoint?.hours}h{addedPoint?.minutes}m
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
        )}
        {isClaimShowPopup && (
          <div className='flex items-center gap-1'>
            <img src={Alert} className='w-4' alt='' />
            <span className='font-medium text-sm text-[#FC0332]'>Your storage is full</span>
          </div>
        )}
      </div>
    </>
  );
};
