import Button from '@/components/button/button';
import { useAccountInfoContext } from '@/contexts/account-info.context';
import { UserRepository } from '@/repository/user.repository';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import '@dotlottie/react-player/dist/index.css';
import { DotLottiePlayer } from '@dotlottie/react-player';
export const Step1 = () => {
  const { refetchQuest, refetchProfile } = useAccountInfoContext();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className='flex flex-col items-center justify-between relative gap-5 h-full w-full min-h-[100svh] step-1'>
      <div className='h-[150px] flex flex-col items-center justify-end w-full'>
        <div className='font-normal text-[#948E9C] text-[40px] text-center'>Daily Check-in You get</div>
      </div>
      <div className='w-full flex flex-col items-center gap-2'>
        <span className='text-[256px] font-[400] leading-[256px] secondary-font'>5</span>
        <span className='font-[400] text-[32px] text-[#E1D6EF]   secondary-font text-center'>HYPE POINTS</span>
        <div className='absolute top-[10%]'>
          <DotLottiePlayer src={'/lotties/success.lottie'} autoplay loop={true}></DotLottiePlayer>
        </div>
      </div>
      <div className='w-full flex flex-col items-center'>
        <div className='text-[400] font-sm w-[90%] text-center primary-font'>
          Check-ins are your best friend here. Keep them going for bonuses that are straight.
        </div>
        <Button
          className='mt-4 w-full'
          isLoading={isLoading}
          onClick={async () => {
            setIsLoading(true);
            try {
              await UserRepository.updateQuest('Daily Checkin');
              refetchQuest();
              refetchProfile();
              setIsLoading(false);
            } catch (err: any) {
              toast.error(err?.message || err || 'Something went wrong! Please reload page or try again later!');
              setIsLoading(false);
            }
          }}
        >
          Claim
        </Button>
      </div>
    </div>
  );
};
