import { Layout } from '@/components/layout/layout';
import { Wrapper } from './referral.styled';
import React, { memo, useMemo, useState } from 'react';
import RoundedLogo from '@/assets/logo/logo-rounded.png';
import { ReactComponent as RefreshIcon } from '@/assets/common-icon/refresh.svg';
import { useAccountInfoContext } from '@/contexts/account-info.context';
import { appConfig, lowerCacheOptions } from '@/constants/app-constaint';
import { copyTextToClipboard } from '@/utilities/string.utils';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectToken } from '@/redux';
import { UserRepository } from '@/repository/user.repository';
import { toast } from 'react-toastify';
import Top1 from '@/assets/leaderboard/top-1.png';
import Top2 from '@/assets/leaderboard/top-2.png';
import Top3 from '@/assets/leaderboard/top-3.png';
export const ReferralComponent = () => {
  const top = [Top1, Top2, Top3];
  const { referral } = useAccountInfoContext();
  const refLink = useMemo(() => {
    return `${appConfig.twaUrl}?startapp=${referral?.code}`;
  }, [referral]);

  const token = useSelector(selectToken);

  const { data: referralHistory, refetch: refetchReferralHistory } = useQuery({
    queryKey: ['getReferralHistory', token],
    queryFn: () => UserRepository.getReferralHistory(),
    retry: false,
    ...lowerCacheOptions,
    enabled: !!token,
    refetchOnMount: 'always'
  });

  const [lastRefetch, setLastRefetch] = useState(0);

  console.log('referralLeaderboard', referralHistory);
  return (
    <Layout>
      <Wrapper className='app-container w-full min-h-[100svh] flex flex-col items-center relative p-4'>
        <div className='secondary-font font-normal text-[32px] text-center text-[#FFFFFF]'>Referral</div>
        <div className='app-linear-border p-4 rounded-lg w-full flex flex-col gap-4 mt-4'>
          <div className='w-full flex'>
            <div className='flex-[0.1]'>
              <img src={RoundedLogo} className='w-10' alt='' />
            </div>
            <div className='flex-[0.8] flex flex-col items-start'>
              <span className='text-[#948E9C] text-sm font-normal'>Build your rocket squad & earn</span>
              <div className='text-[#FFFFFF] text-sm font-medium'>
                +15 POINTs <span className='text-sm text-[#948E9C] font-normal'>for you and your frends</span>
              </div>
            </div>
          </div>
          <div className='w-full flex justify-between items-center'>
            <div className='w-[80%] flex flex-col items-start'>
              <span className='text-[#948E9C] text-sm font-normal'>Recruit Friends</span>
              <div className='text-[#FFFFFF] text-sm font-medium w-full truncate'>{refLink}</div>
            </div>
            <div className='w-[10%] min-w-[44px] flex justify-end'>
              <div
                className='cursor-pointer copy-button  py-1 px-2 !border-[1px] rounded !bg-[#051928] text-[#FFFFFF] text-xs font-normal text-center flex items-center justify-center'
                onClick={async () => await copyTextToClipboard(refLink || '')}
              >
                Copy
              </div>
            </div>
          </div>
        </div>
        <div className='flex w-full items-center justify-between mt-6'>
          <div className='text-base font-normal text-[#FFFFFF]'>Friends onboarded: {referralHistory?.length}</div>
          <div
            className='flex items-center gap-2 refresh-icon cursor-pointer'
            onClick={() => {
              if (lastRefetch !== 0 && new Date().getTime() - lastRefetch <= 3 * 1000) {
                toast.dismiss();
                toast.info('Refresh too fast!');
                return;
              }
              refetchReferralHistory();
              setLastRefetch(new Date().getTime());
            }}
          >
            <RefreshIcon />
            <span className='text-[#E1D6EF] text-sm font-medium '>Refresh</span>
          </div>
        </div>
        <div className='w-full mt-4 app-linear-border px-4 bg-[#FFFFFF] rounded-lg  relative leaderboard-list'>
          {referralHistory?.map((ld: any, key: number) => {
            return (
              <>
                <div className='w-full flex flex-row-reverse justify-between h-[56px] items-center' key={key}>
                  <div className='text-sm font-medium text-[#FFFFFF]'>{Number(ld?.user_point)?.toFixed(3)}</div>
                  <div className='flex items-center gap-2 flex-row-reverse'>
                    <div className='text-sm font-normal text-[#FFFFFF] max-w-[100px]  truncate'>{ld?.used_by_user}</div>
                    {top[key] ? (
                      <img src={top[key]} className='w-10' alt='' />
                    ) : (
                      <div className='w-10 text-center text-sm font-normal text-[#FFFFFF]'>{key + 1}</div>
                    )}
                  </div>
                </div>
                {key < referralHistory?.length - 1 && <div className='w-full h-[1px] bg-[#402957]'></div>}
              </>
            );
          })}
        </div>
        {/* {false ? (
          <div className='app-linear-border p-4 rounded-lg w-full flex flex-col items-center gap-4 mt-2'>
            <img src={ReferralIcon} className='w-[96px]' alt='' />
            <Button>Recruit a Friend</Button>
          </div>
        ) : (
          <div className='app-linear-border p-4 rounded-lg w-full flex flex-col gap-4 mt-4'>
            <div className='w-full py-4 flex justify-between gap-2'>
              <div className='flex-[0.2] flex items-center justify-start'>
                <span className='text-sm text-[#FFFFFF] font-normal'>1</span>
                <span className='mb-1'>🤴</span>
              </div>
              <div className='flex-[0.4] flex items-center justify-start text-start text-sm text-[#FFFFFF] font-normal'>
                Laura Lance
              </div>
              <div className='flex-[0.4] flex items-center justify-end text-end text-base text-[#FFFFFF] font-semibold'>
                10,000,000 PTS
              </div>
            </div>
          </div>
        )} */}
      </Wrapper>
    </Layout>
  );
};
export const Referral = memo(ReferralComponent);
