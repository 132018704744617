import { Layout } from '@/components/layout/layout';
import { Wrapper } from './leaderboard.styled';
import React, { memo, useMemo } from 'react';
import Top1 from '@/assets/leaderboard/top-1.png';
import Top2 from '@/assets/leaderboard/top-2.png';
import Top3 from '@/assets/leaderboard/top-3.png';
import { useSelector } from 'react-redux';
import { selectToken } from '@/redux';
import { UserRepository } from '@/repository/user.repository';
import { lowerCacheOptions } from '@/constants/app-constaint';
import { useQuery } from '@tanstack/react-query';
import { useAccountInfoContext } from '@/contexts/account-info.context';

export const LeaderboardComponent = () => {
  const token = useSelector(selectToken);
  const { userProfile } = useAccountInfoContext();
  const { data: leaderboard, refetch: refetchLeaderboard } = useQuery({
    queryKey: ['getLeaderboard', token],
    queryFn: () => {
      const res = UserRepository.getLeaderboard();
      return res;
    },
    retry: false,
    ...lowerCacheOptions,
    enabled: !!token,
    refetchOnMount: 'always'
  });
  const top = [Top1, Top2, Top3];
  // const leaderboard = [
  //   {
  //     rank: 1,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   },
  //   {
  //     rank: 2,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   },
  //   {
  //     rank: 3,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   },
  //   {
  //     rank: 4,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   },
  //   {
  //     rank: 5,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   },
  //   {
  //     rank: 4,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   },
  //   {
  //     rank: 5,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   },
  //   {
  //     rank: 4,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   },
  //   {
  //     rank: 5,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   },
  //   {
  //     rank: 4,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   },
  //   {
  //     rank: 5,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   },
  //   {
  //     rank: 4,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   },
  //   {
  //     rank: 5,
  //     name: 'Darlene Robertson',
  //     pts: '10,000,000 POINTs'
  //   }
  // ];
  const myInfo = useMemo(() => {
    return leaderboard?.findIndex((item: any) => item?.id === userProfile?.user_id);
  }, [leaderboard, userProfile]);
  console.log('myInfo', myInfo);

  return (
    <Layout>
      <Wrapper className='app-container flex flex-col items-center relative gap-5'>
        <div className='secondary-font font-normal text-[32px] text-center text-[#FFFFFF]'>Leaderboard</div>
        <div className='w-full relative'>
          <div className='w-full app-linear-border px-4 bg-[#FFFFFF] rounded-lg pb-[50px] relative leaderboard-list'>
            {leaderboard?.map((ld: any, key: number) => {
              return (
                <>
                  <div className='w-full flex flex-row-reverse justify-between h-[56px] items-center' key={key}>
                    <div className='text-sm font-medium text-[#FFFFFF]'>{Number(ld?.point)?.toFixed(3)}</div>
                    <div className='flex items-center gap-2 flex-row-reverse'>
                      <div className='text-sm font-normal text-[#FFFFFF] max-w-[100px]  truncate'>{ld?.username}</div>
                      {top[key] ? (
                        <img src={top[key]} className='w-10' alt='' />
                      ) : (
                        <div className='w-10 text-center text-sm font-normal text-[#FFFFFF]'>{key + 1}</div>
                      )}
                    </div>
                  </div>
                  {key < leaderboard?.length - 1 && <div className='w-full h-[1px] bg-[#402957]'></div>}
                </>
              );
            })}
          </div>
          {myInfo !== -1 && (
            <div className='absolute w-[99%] bg-[#E1D6EF] left-[2px] bottom-[2px] rounded-lg  flex justify-between p-4 items-center gap-2 flex-row-reverse overflow-hidden'>
              <div className='text-sm font-medium text-[#10051C]'>
                {Number(leaderboard?.[myInfo]?.point)?.toFixed(3)}
              </div>
              <div className='flex items-center gap-2'>
                <div className='w-10 text-center text-sm font-normal text-[#10051C]'>{myInfo + 1}</div>
                <div className='text-sm font-normal text-[#10051C] max-w-[70%] truncate'>
                  (You) {leaderboard?.[myInfo]?.username}
                </div>
              </div>
            </div>
          )}
        </div>
      </Wrapper>
    </Layout>
  );
};
export const Leaderboard = memo(LeaderboardComponent);
